<template>
  <v-container fluid class="pa-0">
    <app-layout image="BG-Empleos.svg">
      <template #title>
        <v-card-title class="subtitle-2 text-sm-h6 text-md-h5 text-lg-h3 white--text mb-n2 mb-lg-4 font-weight-black text-center">
          Portal Ventanilla Única del Gobierno <br> de El Salvador
        </v-card-title>
        <v-card-text class="text-h6 text-sm-h4 text-md-h3 text-lg-h1 text-center white--text font-weight-black">
          Realiza tus trámites<br />
          en un solo lugar
        </v-card-text>
      </template>
      <template #body-content>
        <Tramites class="mt-6" />
      </template>
    </app-layout>
  </v-container>
</template>

<script>
import Tramites from "../components/ventanilla/Tramites.vue";
import AppLayout from "../components/AppLayoutComponent.vue";

export default {
  components: { Tramites, AppLayout },
};
</script>
