<template>
  <div>
    <v-container>
      <v-row class="mx-1">
        <v-col cols="12" sm="6" md="5">
          <h1 class="text-center text-md-start">Trámites disponibles</h1>
        </v-col>
        <v-spacer />
        <v-col cols="12" sm="6" md="5">
          <v-text-field
            dense
            outlined
            rounded
            append-icon="mdi-magnify"
            v-model.lazy="searchText"
          />
        </v-col>
      </v-row>
      <v-row class="mb-6 justify-center">
        <v-col
          v-for="tramite in tramites"
          :key="tramite.index"
          cols="12"
          sm="6"
          md="4"
        >
          <v-card
            class="mx-3 rounded-xl overflow-auto"
            height="320"
            min-width="200"
            :href="tramite.url"
          >
            <v-img v-if="tramite.imagen" height="200" :src="tramite.imagen" />
            <v-skeleton-loader v-else type="image" height="200" />

            <!-- <v-card-text
              style="height: 100px"
              class="d-flex align-center justify-center"
            > -->
            <v-sheet height="110" class="mx-2 mt-2 d-flex align-center justify-center text-center overflow-y-hidden">
              <span class=" text-title text-sm-h6">{{ tramite.titulo }}</span>
            </v-sheet>
            <!-- </v-card-text> -->
          </v-card>
        </v-col>

        <v-btn
          v-show="responseData.length > initial"
          @click="addNumber()"
          class="mt-6 rounded-pill text-none"
          large
          color="textCyan"
        >
          <span class="mx-4 text-md-h6 white--text">Ver más</span>
        </v-btn>
      </v-row>
    </v-container>
    <Categorias class="mt-6" />
  </div>
</template>

<script>
import Categorias from "./Categorias.vue";
export default {
  name: "Tramites",
  components: {
    Categorias,
  },
  data: () => ({
    responseData: [],
    searchText: "",
    initial: 6,
    tramites: [],
  }),
  methods: {
    async getTramites(filter) {
      const response = await this.services.tramites.getTramites({
        enlinea: true,
        ...filter,
      });
      this.responseData = response.data.map((e) => ({
        titulo: e.titulo,
        imagen: `https://simple.sv/${e.modalidades[0].imagen_mediana.replace(
          "public",
          "storage"
        )}`,
        url: e.modalidades[0].url_sitio,
      }));
      this.tramites = this.responseData.slice(0, this.initial);
    },

    addNumber(value = 6) {
      const sum = (this.initial += value);
      this.tramites = this.responseData.slice(0, sum);
    },
  },

  async created() {
    await this.getTramites();
  },

  watch: {
    searchText(newValue) {
      if (typeof newValue == "string") {
        this.getTramites({
          titulo: newValue.trim(),
        });
      }
    },
  },
};
</script>

<style scoped>
h1,
span,
.v-text-field >>> fieldset,
.v-text-field >>> i {
  color: #30a5f0 !important;
}

::-webkit-scrollbar {
  display: none;
}
</style>
