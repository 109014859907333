<template>
    <div class="bg-category py-12">

        <h2 class="text-center mb-3">Explorar categorías</h2>

        <VueSlickCarousel v-if="categorias.length" v-bind="settings">
            <div @click="open(categoria.redirect)" v-for="categoria in categorias" :key="categoria.id">
                <v-banner shaped>
                    <v-avatar tile size="38">
                        <v-img :src="categoria.img" contain />
                    </v-avatar>
                    <span>{{ categoria.texto }}</span>
                </v-banner>

            </div>
        </VueSlickCarousel>
    </div>
</template>
  
<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
    name: "Categorias",
    components: {
        VueSlickCarousel,
    },
    data: () => ({

        categorias: [],
        settings: {
            centerMode: true,
            centerPadding: "20px",
            arrows: false,
            dots: false,
            infinite: true,
            slidesToShow: 5,
            slidesToScroll: 1,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 1000,
            adaptiveHeight: false,
            pauseOnHover: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        },


    }),

    methods: {
        async getCategorias() {
            const response = await this.services.tramites.getCategorias();
            this.categorias = response.data.map((categoria) => ({
                id: categoria.id,
                texto: categoria.nombre,
                redirect: `https://simple.sv/categoria/${categoria.id}`,
                img: "./assets/categorias/Asociaciones_y_fundaciones.svg",
            }))
        },

        open(data) {
            window.open(data, "_self")
        }
    },

    async created() {
        await this.getCategorias()
    }
}
</script>

<style scoped>
span,
h2 {
    color: #30A5F0 !important;
}

.bg-category {
    background-color: #F4F7FD;
}
</style>